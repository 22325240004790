<template>
  <div>
    <b-card-header class="pb-50">
      <h5>
        Filtros
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mb-1"
        >
          <b-form-group
            label="Nome"
            label-for="searchName"
          >
            <b-form-input
              id="name"
              v-model="name"
              @keyup.enter="search"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <b-form-group
            label="Status"
            label-for="searchStatus"
          >
            <v-select
              id="searchStatus"
              multiple
              v-model="status"
              :reduce="status_list => status_list.id"
              :options="statusList"
              :loading="loading.status"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <b-form-group
            label="Tipo"
            label-for="searchType"
          >
            <v-select
              id="searchType"
              multiple
              v-model="lead_pn_type"
              :reduce="lead_pn_type_list => lead_pn_type_list.key"
              :options="leadPnTypes"
              :loading="loading.leadPnTypes"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <b-form-group
            label="Origem"
            label-for="searchSource"
          >
            <v-select
              id="searchSource"
              multiple
              v-model="source"
              :reduce="source_list => source_list.key"
              :options="sources"
              :loading="loading.sources"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <b-form-group
            label="Franqueado"
            label-for="searchFranchise"
          >
            <v-select
              id="searchFranchise"
              multiple
              v-model="franchise"
              :reduce="franchise_list => franchise_list.id"
              :options="franchises"
              :loading="loading.franchises"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <b-form-group
            label="Potencial"
            label-for="searchPotential"
          >
            <v-select
              id="searchPotential"
              multiple
              v-model="potential"
              :reduce="potential_list => potential_list.key"
              :options="potentials"
              :loading="loading.potential"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <dynamic-select
            id="searchRecommendedBy"
            label="Recomendado por"
            placeholder="Digite o nome do cliente"
            v-model="recommendedBy"
            :options="customers"
            :loading="loading.recommendedBy"
            @find="findCustomers"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <b-form-group
            label="Data de criação"
            label-for="searchCreatedAt"
          >
            <flat-pickr
              id="searchCreatedAt"
              v-model="createdAt"
              class="form-control"
              :config="flatPickrConfig"
              :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <b-form-group
            label="Data para lembrar"
            label-for="searchCallReminder"
          >
            <flat-pickr
              id="searchCallReminder"
              v-model="callReminder"
              class="form-control"
              :config="flatPickrConfig"
              :placeholder="`01 Jan 2020 até ${generateTodayDate()}`"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <b-form-group
            label="Tipo de visualização"
            label-for="searchStructureOption"
          >
            <v-select
              id="searchStructureOption"
              v-model="structureOption"
              :reduce="structure_list => structure_list.key"
              :options="structures"
              :loading="loading.structures"
              label="name"
              @keyup.enter="search"
              :disabled="hasConsultantSelected"
              :clearable="false"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <b-form-group
            label="Parceiro de negócio (PN)"
            label-for="searchConsultant"
          > 
            <v-select
              id="searchConsultant"
              multiple
              v-model="consultant"
              :reduce="consultant_list => consultant_list.id"
              :options="consultantList"
              :loading="loading.consultantList"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
              @input="consultantChange"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Poupa"
          >
          <vue-slider
            class="mt-2 primary"
            v-model="monthlySavings"
            :max="10000"
            :direction="dir"
          >
          <template v-slot:tooltip="{ value }">
            <div v-if="value <= 9999" class="custom-tooltip">{{ value | toCurrency }}</div>
            <div v-else class="custom-tooltip">>{{ value | toCurrency }}</div>
          </template>
          </vue-slider>
          </b-form-group>
        </b-col> -->
        <b-col
          cols="12"
          class="text-right"
        >
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BFormInput, BFormDatepicker,
  BFormRadioGroup, BFormRadio, BButton, BSpinner, BPopover, BCardText
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import DynamicSelect from '@/modules/shared/components/DynamicSelect'
import VueSlider from 'vue-slider-component'
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
import todayButton from '@/helpers/todayButton'
import * as types from '../store/types'
import * as sharedTypes from '@/modules/shared/store/types'
import { OWN, STRUCTURE } from '@/constants/structure_options'
import * as accountTypes from '@/modules/account/store/types'
import { 
  APP_BACKOFFICE_ROLE_ID, APP_FINANCIAL_ROLE_ID, APP_ADMIN_ROLE_ID, APP_CUSTOMER_ROLE_ID
} from '@/constants/auth'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import { dateToShortFormat } from "@/helpers/converters";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BSpinner,
    vSelect,
    BCardText,
    BPopover,
    DynamicSelect,
    VueSlider,
    flatPickr
  },
  props:{
    search: Function,
    spinnerActive: Boolean
  },
  data() {
    return {
      loading: {
        goals: false,
        status: false,
        lead_pn_type: false,
        franchise: false,
        potential: false,
        source: false,
        recommendedBy: false,
        consultantList: false,
        structures: false
      },
      flatPickrConfig:{
        altFormat: 'j M Y',
        altInput: true,
        mode: 'range',
        dateFormat: 'Y-m-d',
        locale: Portuguese,
        plugins:[
          ShortcutButtonsPlugin(todayButton)
        ]
      },
      name: undefined,
      createdAt: undefined,
      goal: undefined,
      status: undefined,
      lead_pn_type: undefined,
      franchise: undefined,
      potential: undefined,
      source: undefined,
      recommendedBy: undefined,
      callReminder: undefined,
      structureOption: OWN,
      consultant: undefined,
      dir: 'ltr'
    }
  },
  setup() {
    const actualYear = new Date().getFullYear()
    return { toast: useToast(), actualYear, dateToShortFormat }
  },
  computed: {
    ...mapGetters({
      goals: types.GOALS,
      franchises: sharedTypes.FRANCHISES,
      customers: sharedTypes.CUSTOMERS,
      statusList: types.LEAD_STATUSES,
      leadPnTypes: types.LEAD_PN_TYPES,
      potentials: types.LEAD_POTENTIALS,
      sources: types.SOURCES,
      structures: sharedTypes.STRUCTURE_TYPES,
      consultantList: sharedTypes.CONSULTANTS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      user: accountTypes.USER
    }),
    hasConsultantSelected: function(){
      return this.consultant && this.consultant.length > 0
    },
    isPn: function () {
      return this.user?.user_role_id != APP_BACKOFFICE_ROLE_ID
        && this.user?.user_role_id != APP_FINANCIAL_ROLE_ID
        && this.user?.user_role_id != APP_ADMIN_ROLE_ID
        && this.user?.user_role_id != APP_CUSTOMER_ROLE_ID
    },
  },
  mounted() {
    this.structureOption = this.isPn? OWN:STRUCTURE
    this.loading.franchise = true
    this.getFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as faixas de renda para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.franchises = false
      })
      this.loading.sources = true
      this.getSources()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as origens para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.sources = false
        })
      this.loading.potentials = true
      this.getPotentials()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os potentiais para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.potentials = false
        })
      this.loading.status = true
      this.getStatuses()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os status para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.status = false
        })
      this.loading.lead_pn_type = true
      this.getCustomerTypes()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os tipos para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.lead_pn_type = false
        })
      this.loading.structures = true
      this.getStructures()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as estruturas para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.structures = false
        })

      this.loading.consultantList = true
      this.getConsultants()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.consultantList = false
        })
  },
  methods: {
    ...mapActions({
      getFranchises: sharedTypes.GET_FRANCHISES,
      getSources: types.GET_SOURCES,
      getCustomers: sharedTypes.GET_CUSTOMERS,
      getStatuses: types.GET_LEAD_STATUSES,
      getCustomerTypes: types.GET_LEAD_PN_TYPES,
      getStructures: sharedTypes.GET_STRUCTURE_TYPES,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getPotentials: types.GET_LEAD_POTENTIALS
    }),
    findCustomers(keyword){
      this.loading.recommendedBy = true
      this.getCustomers({ keyword })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os clientes para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
        .finally(() => {
          this.loading.recommendedBy = false
        });
    },
    consultantChange(){
      if(this.consultant.length > 0){
        this.structureOption = STRUCTURE
      }
    },
    generateTodayDate() {
      return this.dateToShortFormat(new Date())
    }
  }
}
</script>

<style lang="scss">
$themeColor: #f26237;
@import '~vue-slider-component/lib/theme/default.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-group {
  margin-bottom: 0;
}
</style>
